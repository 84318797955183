<template>
  <div class="ignore">
    <!-- toggleable="lg"  isMobile?'absoult-top':''-->
    <b-navbar
      toggleable="lg"
      type="dark"
      style="width: 100%"
      fixed="top"
      :class="[bgColor1 ? 'bg-dark1' : 'bg-dark2']"
    >
      <div class="container-fluid">
        <b-navbar-brand :href="getUrl()" style="min-width: 165px">
          <img v-if="logoUrl.length > 0" :src="baseUrl + logoUrl + ''" alt="" />
          <img v-else src="../assets/img/imgs/logo.png" alt="" />
        </b-navbar-brand>
        <b-navbar-toggle
          target="nav-collapse"
          @click="openOrCloseDropMenu(true)"
          style="position: fixed; right: 32px"
        ></b-navbar-toggle>
        <!--  全屏菜单 v-if="showTopNav"-->
        <template v-if="!isMobile || showTopNav">
          <b-navbar-nav
            class="ml-auto"
            style="
              text-transform: capitalize;
              white-space: nowrap;
              flex-wrap: wrap;
              justify-content: end;
            "
          >
            <template v-for="menuItem in navList">
              <b-nav-item v-if="menuItem.child" :key="menuItem.id">
                <div class="drop-li">
                  <b-nav-item-dropdown right>
                    <template v-slot:button-content>
                      <img
                        v-if="menuItem.litpic.length > 0 && menuItem.child"
                        :key="menuItem.nav_url + 'icon'"
                        :src="baseUrl + menuItem.litpic"
                        alt=""
                        class="icon-20"
                      />
                      {{ transHeader(menuItem) }}
                    </template>
                    <template v-for="child in menuItem.child">
                      <b-dropdown-item
                        :key="child.id"
                        :to="{
                          path:
                            child.nav_url === '/main'
                              ? child.nav_url + `/${child.type_id}`
                              : child.nav_url,
                          query: {
                            type_id: child.type_id,
                            target: child.target,
                            tab: getMenuTab(child),
                            headpic: child.headpic,
                            headmov: child.headmov,
                          },
                        }"
                      >
                        <img
                          v-if="child.litpic.length > 0"
                          :src="baseUrl + child.litpic"
                          alt=""
                          class="icon-20"
                        />
                        {{ transHeader(child) }}
                      </b-dropdown-item>
                    </template>
                  </b-nav-item-dropdown>
                </div>
              </b-nav-item>
              <b-nav-item
                v-else-if="menuItem.nav_url === '/'"
                :key="menuItem.id"
                :to="{ path: `/` }"
              >
                <img
                  v-if="menuItem.litpic.length > 0"
                  :src="baseUrl + menuItem.litpic"
                  alt=""
                  class="icon-20 margin-lr-10"
                />
                <span class="margin-lr-10">|</span>
              </b-nav-item>
              <b-nav-item
                v-else
                :key="menuItem.id"
                :to="{
                  path:
                    menuItem.nav_url === '/main'
                      ? menuItem.nav_url + `/${menuItem.type_id}`
                      : menuItem.nav_url ,
                  query: {
                    type_id: menuItem.type_id,
                    target: menuItem.target,
                    tab: getMenuTab(menuItem),// menuItem.nav_name,
                    headpic: menuItem.headpic,
                    headmov: menuItem.headmov,
                  },
                }"
              >
                <img
                  v-if="menuItem.litpic.length > 0"
                  :src="baseUrl + menuItem.litpic"
                  alt=""
                  class="icon-20"
                />
                {{ transHeader(menuItem) }}
              </b-nav-item>
            </template>
            <b-navbar-brand>
              <div class="select-box" id="translate">
                <el-select v-model="value" class="select-css" @change="changLang" popper-class="drop-css">
                  <el-option value="EN" label="English">English</el-option>
                  <el-option value="TC" label="繁體中文">繁體中文</el-option>
                  <el-option value="SC" label="简体中文">简体中文</el-option>
                </el-select>
              </div>
            </b-navbar-brand>
          </b-navbar-nav>
        </template>

        <!--      收缩后菜单-->
        <template v-if="isMobile">
          <collpaseNav
            :drawer="drawerShow"
            @close="openOrCloseDropMenu"
            :menuList="navList"
          ></collpaseNav>
          <div id="translate" style="visibility: hidden; width: 0px"></div>
        </template>
      </div>
    </b-navbar>
  </div>
</template>

<script>
/* eslint-disable */
// import translate from "i18n-jsautotranslate";
import { getConfig, getNavList } from "../axios/api";
import { stores } from "../utils/store.js";
import collpaseNav from "@/components/collpaseNav";
export default {
  name: "headerComponent",
  components: {
    collpaseNav,
  },
  data() {
    return {
      bgColor1: false,
      navList: [],
      logoUrl: "",
      baseUrl: "https://admin.yuenlongbarrage.hk/",
      screenWidth: 0,
      showTopNav: true,
      drawerShow: false,
      isMobile: false,
      fullscreenLoading: false,
      lang:'',
      value:'TC'
    };
  },
  computed: {
    transHeader() {
      return function (data) {
        let str = "";
        const lang = window.translate.language.getCurrent();
        if (lang === "english") {
          this.value = "EN"          
          str = data.en_name;
        } else if (lang === "chinese_traditional") {
          str = data.tc_name;
          this.value = "TC"
        } else {
          this.value = "SC"
          str = data.nav_name;
        }
        // console.log('tete', str, lang)
        return str;
      };
    },
  },
 async mounted() {
    this.getEnv();
    this.bgChange(this.$store.state.scrollTop);
    this.getNavList();
    this.getScreen();
    this.trans();
    this.lang = stores.getLang(window.translate.language.getCurrent())
    const _that = this
    // window.translate.selectLanguageTag.selectOnChange = async function (event) {
    //   let language = event.target.value;
    //   window.translate.changeLanguage(language);
    //   await stores.updateRoute(language)
    // };

  },

  watch: {
    "$store.state.scrollTop"(newval, oldval) {
      this.bgChange(newval);
    },
    screenWidth: {
      handler(val) {
        if (val <= 991) {
          this.showTopNav = false;
          window.translate.selectLanguageTag.show = false;
          // translate.request.api.host = "https://tr.yuenlongbarrage.hk/"; // 将这里面的ip地址换成你服务器的ip，注意开头，及结尾还有个 / 别拉下
          // 指定翻译服务为使用 translate.service
          window.translate.execute();
        } else {
          this.showTopNav = true;
          this.$nextTick(() => {
            // window.translate.selectLanguageTag.show = true;
            // translate.request.api.host = "https://tr.yuenlongbarrage.hk/"; // 将这里面的ip地址换成你服务器的ip，注意开头，及结尾还有个 / 别拉下
            // 指定翻译服务为使用 translate.service
            // window.translate.execute();
          });
        }
      },
    },
  },
  created() {
    this.getConfig();
    // window.translate.language.setLocal('english')
    // window.translate.language.setDefaultTo('english')
    // window.translate.forceUpdate()
    // localStorage.setItem("to", window.translate.language.getCurrent())
    // console.log('header created', window.translate.language.getCurrent() );
  },

  methods: {
    getMenuTab(menu){
      const lang = localStorage.getItem("to");
      if (lang === "english") {
        return menu.en_name
      }
      if (lang === "chinese_simplified") {
        return menu.nav_name
      }
      if (lang === "chinese_traditional") {
        return menu.tc_name
      }
      return menu.nav_name;
    },

    getScreen() {
      this.screenWidth = document.body.clientWidth;
      window.onresize = () => {
        return (() => {
          this.screenWidth = document.body.clientWidth;
        })();
      };
    },
    getEnv() {
      const userAgent = window.navigator.userAgent;
      if (/Mobile|Android|iPhone|iPod|iPad|Windows Phone/i.test(userAgent)) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    trans() {
      if(!this.mobile){
        window.translate.selectLanguageTag.show = true;
        window.translate.selectLanguageTag.refreshRender()
      }
      window.translate.listener.start()
      window.translate.execute(); // 进行翻译
    },
    bgChange(scrolltop) {
      if (scrolltop < 100) {
        this.bgColor1 = true;
      } else {
        this.bgColor1 = false;
      }
    },
    getNavList() {
      getNavList().then((res) => {
        console.log("[ res.data ] => ", res.data);
        this.navList = res.data;
        // translate.request.api.host = "https://tr.yuenlongbarrage.hk/"; // 将这里面的ip地址换成你服务器的ip，注意开头，及结尾还有个 / 别拉下
        // 指定翻译服务为使用 translate.service
        window.translate.execute();
      });
    },
    getLogoUrl() {
      if (sessionStorage.getItem("pageConfig")) {
        const data = JSON.parse(sessionStorage.getItem("pageConfig"));
        this.logoUrl = data.configs.web_logo;
      }
    },
    async getConfig() {
      await getConfig().then((res) => {
        this.logoUrl = res.data.configs.web_logo;
        sessionStorage.setItem("pageConfig", JSON.stringify(res.data));
        // translate.request.api.host = "https://tr.yuenlongbarrage.hk/"; // 将这里面的ip地址换成你服务器的ip，注意开头，及结尾还有个 / 别拉下
        // 指定翻译服务为使用 translate.service
        window.translate.execute();
      });
    },
    getUrl() {
      let str = "";
      const lang = window.translate.language.getCurrent();
      if (lang === "english") {
        str = "https://www.dsd.gov.hk/EN/Home/index.html";
      }
      if (lang === "chinese_simplified") {
        str = "https://www.dsd.gov.hk/SC/Home/index.html";
      }
      if (lang === "chinese_traditional") {
        str = "https://www.dsd.gov.hk/TC/Home/index.html";
      }
      return str;
    },
    openOrCloseDropMenu(flag) {
      this.drawerShow = flag;
    },
    changLang(val){
      if(val === 'EN') {
        localStorage.setItem('to', 'english')//英文打包
        location.href='https://www.yuenlongbarrage.hk/EN/#/index'
      } else if(val === 'TC') {
        localStorage.setItem('to', 'chinese_traditional')//繁体打包
        location.href='https://www.yuenlongbarrage.hk/TC/#/index'
      } else {
        localStorage.setItem('to', 'chinese_simplified')//简体打包
        location.href='https://www.yuenlongbarrage.hk/SC/#/index '
      }
    }
  },
};
</script>

<style lang="less">
.nav-item > a {
  display: inline-flex;
  align-items: center;
  img {
    margin-right: 10px;
  }
}
.nav-link {
  text-decoration: none !important;
  color: inherit !important;
  font-size: 16px !important;
  font-weight: 500;
  text-shadow: 0 0 5px #000, 0 0 2px #000;
  font-family: "Noto Sans SC", "Microsoft JhengHei", "微軟正黑體",
    "Microsoft YaHei", "微软雅黑体", Helvetica, Arial, PMingLiU, "新細明體",
    "宋体", simsun, sans-serif;
}
.nav-link a {
  font-weight: 500;
}
.dropdown-item {
  text-shadow: 0 0 black !important;
}
.dropdown-item a {
  text-decoration: none !important;
  color: inherit !important;
}
#translateSelectLanguage {
  /*background: rgb(39, 39, 61) !important;*/
  background: transparent !important;
  color: white;
  border: 1px solid #ffffff;
  border-radius: 4px;
  font-size: 18px;
  font-weight: 500;
  margin-left: 15px;
  width: 100px;
}
select option {
  background: #ffffff;
  color: #23272b;
}

.navbar-dark .navbar-nav .nav-link {
  color: #ffffff !important;
}
.navbar {
  transition-duration: 500ms;
}
.bg-dark1 {
  background: transparent !important;
}
.bg-dark2 {
  background: #27273d !important;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .show {
    /* background: #27273D !important;*/
    padding-left: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .show {
    /*  background: #27273D !important;*/
    padding-left: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .show {
    /* background: #27273D !important;*/
    padding-left: 15px;
  }
}
.icon-20 {
  width: 24px;
  height: 24px;
}
.icon-32 {
  width: 32px;
  height: 32px;
}
.ml-auto {
  align-items: baseline;
}
.margin-lr-10 {
  margin-left: 10px;
  margin-right: 10px;
}
.drop-li {
  display: flex;
  align-items: center;
}
.margin-right-5 {
  margin-right: 7px;
  margin-top: 5px;
}
@media (min-width: 1400px) {
  .container-xxxl {
    max-width: 1400px !important;
  }
}
.navbar-brand {
  float: right;
}

.absoult-top {
  position: absolute !important;
}
//修改动画的大小  给文字加粗效果
.el-loading-spinner {
  font-size: 70px;
  font-weight: bold;
}
//修改文字的大小
.el-loading-mask .el-loading-spinner .el-loading-text {
  font-size: 24px;
}

.el-select .el-input__inner{
  background: transparent !important;
  font-size: 20px !important;
  color: white !important;
  width: 140px !important;

}
.el-select-dropdown__list{
  background: transparent !important;
}
.el-select-dropdown{
  background: transparent !important;
}
.el-range-editor.is-active, .el-range-editor.is-active:hover, .el-select .el-input.is-focus .el-input__inner{
  border-color: white !important;
}
.el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
  background: transparent !important;
}
.el-select-dropdown__item{
  color: white !important;
}
.el-select-dropdown__item{
  color: white !important;
}
</style>
