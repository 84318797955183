<template>
  <div>
    <Notice :new-title="noticeTitle" v-if="show === 0"></Notice>
    <bGround v-else-if="show === 1" :title="title" :bg="bg" :bgVideo="bgVideo"></bGround>
  </div>
</template>

<script>
import Notice from '@/components/notice'
import bGround from '@/components/bGround'
export default {
  name: 'bgCotainerComponent',
  components: {
    Notice,
    bGround
  },
  data () {
    return {
      show: 1,
      bg: '',
      bgVideo: '',
      title: ''
    }
  },
  props: {
    noticeTitle: String
  },
  watch: {
    $route (to, from) {
      this.showBG(to)
    }
  },
  mounted () {
    // window.translate.request.api.host = 'https://tr.yuenlongbarrage.hk/' // 将这里面的ip地址换成你服务器的ip，注意开头，及结尾还有个 / 别拉下
    // // 指定翻译服务为使用 translate.service
    window.translate.execute()
  },
  created () {
    this.showBG(this.$route)
  },
  methods: {
    showBG (route) {
      if (route.name === 'index') {
        this.show = 0
      } else if (route.name === 'main') {
        this.show = 1
        this.title = route.query.tab
        this.bg = route.query.headpic
        this.bgVideo = route.query.headmov
      } else {
        this.title = route.meta.tab
        this.show = 1
        this.bg = route.query.headpic
        this.bgVideo = route.query.headmov
      }
      window.translate.execute()
    }
  }
}
</script>

<style scoped>

</style>
