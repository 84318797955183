var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ignore"},[_c('b-navbar',{class:[_vm.bgColor1 ? 'bg-dark1' : 'bg-dark2'],staticStyle:{"width":"100%"},attrs:{"toggleable":"lg","type":"dark","fixed":"top"}},[_c('div',{staticClass:"container-fluid"},[_c('b-navbar-brand',{staticStyle:{"min-width":"165px"},attrs:{"href":_vm.getUrl()}},[(_vm.logoUrl.length > 0)?_c('img',{attrs:{"src":_vm.baseUrl + _vm.logoUrl + '',"alt":""}}):_c('img',{attrs:{"src":require("../assets/img/imgs/logo.png"),"alt":""}})]),_c('b-navbar-toggle',{staticStyle:{"position":"fixed","right":"32px"},attrs:{"target":"nav-collapse"},on:{"click":function($event){return _vm.openOrCloseDropMenu(true)}}}),(!_vm.isMobile || _vm.showTopNav)?[_c('b-navbar-nav',{staticClass:"ml-auto",staticStyle:{"text-transform":"capitalize","white-space":"nowrap","flex-wrap":"wrap","justify-content":"end"}},[_vm._l((_vm.navList),function(menuItem){return [(menuItem.child)?_c('b-nav-item',{key:menuItem.id},[_c('div',{staticClass:"drop-li"},[_c('b-nav-item-dropdown',{attrs:{"right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [(menuItem.litpic.length > 0 && menuItem.child)?_c('img',{key:menuItem.nav_url + 'icon',staticClass:"icon-20",attrs:{"src":_vm.baseUrl + menuItem.litpic,"alt":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.transHeader(menuItem))+" ")]},proxy:true}],null,true)},[_vm._l((menuItem.child),function(child){return [_c('b-dropdown-item',{key:child.id,attrs:{"to":{
                        path:
                          child.nav_url === '/main'
                            ? child.nav_url + `/${child.type_id}`
                            : child.nav_url,
                        query: {
                          type_id: child.type_id,
                          target: child.target,
                          tab: _vm.getMenuTab(child),
                          headpic: child.headpic,
                          headmov: child.headmov,
                        },
                      }}},[(child.litpic.length > 0)?_c('img',{staticClass:"icon-20",attrs:{"src":_vm.baseUrl + child.litpic,"alt":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.transHeader(child))+" ")])]})],2)],1)]):(menuItem.nav_url === '/')?_c('b-nav-item',{key:menuItem.id,attrs:{"to":{ path: `/` }}},[(menuItem.litpic.length > 0)?_c('img',{staticClass:"icon-20 margin-lr-10",attrs:{"src":_vm.baseUrl + menuItem.litpic,"alt":""}}):_vm._e(),_c('span',{staticClass:"margin-lr-10"},[_vm._v("|")])]):_c('b-nav-item',{key:menuItem.id,attrs:{"to":{
                path:
                  menuItem.nav_url === '/main'
                    ? menuItem.nav_url + `/${menuItem.type_id}`
                    : menuItem.nav_url ,
                query: {
                  type_id: menuItem.type_id,
                  target: menuItem.target,
                  tab: _vm.getMenuTab(menuItem),// menuItem.nav_name,
                  headpic: menuItem.headpic,
                  headmov: menuItem.headmov,
                },
              }}},[(menuItem.litpic.length > 0)?_c('img',{staticClass:"icon-20",attrs:{"src":_vm.baseUrl + menuItem.litpic,"alt":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.transHeader(menuItem))+" ")])]}),_c('b-navbar-brand',[_c('div',{staticClass:"select-box",attrs:{"id":"translate"}},[_c('el-select',{staticClass:"select-css",attrs:{"popper-class":"drop-css"},on:{"change":_vm.changLang},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('el-option',{attrs:{"value":"EN","label":"English"}},[_vm._v("English")]),_c('el-option',{attrs:{"value":"TC","label":"繁體中文"}},[_vm._v("繁體中文")]),_c('el-option',{attrs:{"value":"SC","label":"简体中文"}},[_vm._v("简体中文")])],1)],1)])],2)]:_vm._e(),(_vm.isMobile)?[_c('collpaseNav',{attrs:{"drawer":_vm.drawerShow,"menuList":_vm.navList},on:{"close":_vm.openOrCloseDropMenu}}),_c('div',{staticStyle:{"visibility":"hidden","width":"0px"},attrs:{"id":"translate"}})]:_vm._e()],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }